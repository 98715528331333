<template>
  <div>
      <vx-card id="searchTable" ref="filterCard" title="Ventas en Tienda" class="user-list-filters mb-8">
          <div class="vx-row">
              <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
                  <label class="text-sm opacity-75">Tienda</label>
                  <v-select v-model="idStore" :clearable="false" :options="storeOptions" :reduce="idStore => idStore.value" v-validate="'required'" data-vv-as="Tienda" name="store" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                  <span class="text-danger text-sm" v-show="errors.has('store')">{{ errors.first('store') }}</span>
              </div>
              <div class="vx-col md:w-1/4 sm:w-1/2 w-full mt-5 ag-grid-table-actions-right">
                  <vs-button id="searchButton" class="mb-4 md:mb-0" @click="fetchSales" :disabled="!isFormValid">Aplicar</vs-button>
              </div>
          </div>
      </vx-card>

      <div class="vx-card p-6 vs-con-loading__container" ref="content">
          <!-- TABLE ACTION ROW -->
          <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                  class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                  <span class="mr-2"
                  >{{
                      currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                      sales.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : sales.length
                  }}
                  de {{ sales.length }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                  </vs-dropdown-item>
              </vs-dropdown-menu>
              </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
              <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
              <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
              <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
              <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
              <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Buscar..."
              />
              <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
              >Exportar</vs-button
              >
          </div>
          </div>
          <ag-grid-vue
              ref="agGridTable"
              :gridOptions="gridOptions"
              class="ag-theme-material w-100 my-4 ag-grid-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="sales"
              @first-data-rendered="onFirstDataRendered"
              rowSelection="single"
              colResizeDefault="shift"
              :animateRows="true"
              :pagination="true"
              :paginationPageSize="paginationPageSize"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl"
              :modules="modules"
          >
          <!-- @selection-changed="onSelectionChanged" -->
          </ag-grid-vue>
          <ag-grid-vue style="height: 60px; flex: none;"
            class="ag-theme-material w-100 ag-grid-table total"
            :gridOptions="bottomGridOptions"
            :headerHeight="0"
            :columnDefs="columnDefs"
            :floatingFiltersHeight="0"
            :rowData="summaryFooter"
            :modules="modules">
          </ag-grid-vue>
          <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
          />
      </div>
  </div>
</template>
<script>
import * as lang from 'vuejs-datepicker/src/locale';
import DatePicker from "vuejs-datepicker";
import vSelect from 'vue-select'
import { AgGridVue } from "@ag-grid-community/vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import reportModule from "@/store/report/moduleReport.js";
import moduleMarketplace from "@/store/marketplace/moduleMarketplace.js"
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js";
import { AllCommunityModules } from '@ag-grid-community/all-modules';

export default {
  components: {
      vSelect,
      AgGridVue,
      AllCommunityModules,
      DatePicker
  },
  data() {
      return {
          from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          languages: lang,
          idStore: null,
          sales: [],
          storeOptions: [],
          mockData: [
              {
                  "id_account": 100,
                  "pupil_code": 3042,
                  "pupil_display_name": "Castor Morrison",
                  "guardian_display_name": "Uma Lawson",
                  "amount": "$284.21",
                  "total_default": "$2,306.81",
                  "days_of_default": 3,
                  "payment_date": "03-05-2022",
                  "status": 1
              },
              {
                  "id_account": 101,
                  "pupil_code": 3302,
                  "pupil_display_name": "Adele Sosa",
                  "guardian_display_name": "Nell Bentley",
                  "amount": "$221.22",
                  "total_default": "$809.57",
                  "days_of_default": 10,
                  "payment_date": "20-12-2022",
                  "status": 1
              },
              {
                  "id_account": 102,
                  "pupil_code": 2091,
                  "pupil_display_name": "Marsden Mercer",
                  "guardian_display_name": "Kyle Fitzpatrick",
                  "amount": "$152.80",
                  "total_default": "$2,970.75",
                  "days_of_default": 5,
                  "payment_date": "25-02-2024",
                  "status": 1
              },
              {
                  "id_account": 103,
                  "pupil_code": 2437,
                  "pupil_display_name": "Dai Ross",
                  "guardian_display_name": "Galvin Christensen",
                  "amount": "$394.80",
                  "total_default": "$4,343.35",
                  "days_of_default": 6,
                  "payment_date": "12-04-2022",
                  "status": 1
              },
              {
                  "id_account": 104,
                  "pupil_code": 2279,
                  "pupil_display_name": "Keane Valenzuela",
                  "guardian_display_name": "Yoshio Sargent",
                  "amount": "$129.38",
                  "total_default": "$6,984.67",
                  "days_of_default": 7,
                  "payment_date": "14-08-2022",
                  "status": 1
              }
          ],

          //AgGrid
          modules: AllCommunityModules,
          searchQuery: "",
          maxPageNumbers: 5,
          gridApi: null,
          defaultColDef: {
              sortable: true,
              editable: false,
              resizable: true,
              suppressMenu: true,
          },
          // gridOptions: {
          //     alignedGrids: [],
          //     suppressHorizontalScroll: true,
          //     floatingFiltersHeight: 0,
          // },
          gridOptions: {
            alignedGrids: [],
            suppressHorizontalScroll: true,
            context: {}
          },
          columnDefs: [
              {
                headerName: 'ID',
                field: 'id_sale',
                filter: true,
                sort: 'desc',
              },
              {
                headerName: "Tienda",
                sortable: true,
                field: "store_name",
                width: 120,
                filter: true,
                cellClass: "cellMayusc",
              },
              {
                headerName: 'Fecha',
                field: 'sale_date',
                width: 160,
                filter: true,
                comparator: this.myDateComparator
              },
              {
                headerName: 'Producto',
                field: 'title_product',
                filter: true,
                floatingFilter: true,
                width: 220
              },
              {
                headerName: 'SKU',
                field: 'sku',
                filter: true,
                floatingFilter: true,
                width: 160,
              },
              {
                headerName: 'Categoría',
                field: 'name_category',
                filter: true,
                floatingFilter: true,
                width: 320
              },
              {
                headerName: 'Comprador',
                field: 'guardian.name',
                filter: true,
                floatingFilter: true,
                width: 320
              },
              {
                headerName: 'Precio Unit.',
                field: 'price',
                filter: false,
                width: 320,
                valueFormatter: params => this.$options.filters.currencySign(params.data.price, '$ '),
              },
              {
                headerName: 'Cantidad',
                field: 'quantity',
                filter: false,
                width: 320
              },
              {
                headerName: 'Total',
                field: 'total',
                filter: false,
                width: 320,
                valueFormatter: params => this.$options.filters.currencySign(params.data.total, '$ '),
              },
              {
                headerName: 'Receptor',
                field: 'receiver_name',
                filter: true,
                floatingFilter: true,
                width: 320
              },
              {
                headerName: 'Documento Receptor',
                field: 'document',
                filter: true,
                floatingFilter: true,
                width: 320
              },
          ],

          // Bottom Summary Row
          bottomGridOptions: {
            alignedGrids: [],
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
                floatingFiltersHeight: 0
            }
          },

          summaryFooter: [],
      }
  },
  methods: {
      setLoading(val) {
        if(val) {
          this.$vs.loading({
            container: this.$refs.content,
            scale: 0.5,
          })
        } else
            this.$vs.loading.close(this.$refs.content)

      },
      fetchSales() {
          this.sales = [];
          this.summaryFooter = [];

          this.$validator.validateAll().then(result => {
              if(!result) return
              if (this.idStore > 0) {
                this.setLoading(true)

                this.$store.dispatch("reportModule/getMarketplaceSales", this.getQueryParams())
                .then((response) => {
                    if (response && response.length > 0) {
                    let sales = JSON.parse(JSON.stringify(response));
                    const store = this.$store.getters['marketplace/getStore'](this.idStore)

                    let s_footer = {};
                    s_footer.id_sale = "Total";
                    s_footer.total = 0;

                    sales.forEach(element => {
                        element.store_name = store.name
                        element.sale_date = new Date(element.sale_date).toLocaleDateString('es-AR', this.$localeDateOptions)
                        element.receiver_name = element.name ? `${element.name} ${element.last_name}` : ''
                        s_footer.total += element.total;
                    });

                    this.summaryFooter = [s_footer];
                    this.sales = sales;
                    }
                })
                .finally(() => this.setLoading(false) )
                .catch((error) => {
                    console.log(error);
                });
            }
          })
      },
      isDate(val) {
          return val instanceof Date && !isNaN(val.valueOf())
      },
      getQueryParams() {
        const dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};

        let params = `id_store=${this.idStore}&from=${dates.from}&to=${dates.to}`

        return params
      },
      resolvePeriod(period) {
          if(!period) return
          const p = new Date(period)
          return p.toLocaleString('default', { month: 'long' }) + ' ' + p.getFullYear()
      },
      getStores() {
          if(this.storeOptions && this.storeOptions.length) return

          this.$store.dispatch(`marketplace/getStores`, '')
          .then(() => {
            const stores = this.$store.getters['marketplace/getStores']
            stores.forEach(element => {
              this.storeOptions.push({label: element.name, value: element.id})
            });
          })
          .catch(err => console.log(err))

      },
      isFormValid() {
          return !this.errors.any() && (this.idStore > 0)
      },
      onFirstDataRendered: function() {
          this.gridColumnApi.autoSizeAllColumns();
      },
      updateSearchQuery(val) {
        this.gridApi.setQuickFilter(val);
      },
  },
  computed: {
      paginationPageSize() {
          if (this.gridApi) return this.gridApi.paginationGetPageSize();
          else return 50;
      },
      totalPages() {
          if (this.gridApi) return this.gridApi.paginationGetTotalPages();
          else return 0;
      },
      currentPage: {
          get() {
              if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
              else return 1;
          },
          set(val) {
              this.gridApi.paginationGoToPage(val - 1);
          },
      },
  },
  watch: {
    from: function () {
      this.fetchSales();
    },
    to: function () {
      this.fetchSales();
    },
  },
  created() {
      if (!reportModule.isRegistered) {
          this.$store.registerModule("reportModule", reportModule);
          reportModule.isRegistered = true;
      }
      if(!moduleMarketplace.isRegistered) {
          this.$store.registerModule('marketplace', moduleMarketplace)
          moduleMarketplace.isRegistered = true
      }
      if (!moduleUserManagement.isRegistered) {
          this.$store.registerModule("userManagement", moduleUserManagement);
          moduleUserManagement.isRegistered = true;
      }

      this.getStores();
      this.fetchSales();
  },
  mounted() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;

      if (this.$vs.rtl) {
          const header = this.$refs.agGridTable.$el.querySelector(
              ".ag-header-container"
          );
          header.style.left =
              "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
      }
  },
  beforeMount() {
    this.gridOptions.alignedGrids.push(this.bottomGridOptions);
    this.bottomGridOptions.alignedGrids.push(this.gridOptions);
  },
}
</script>
<style>
.cellMayusc {
  text-transform: capitalize;
}
.ag-grid-table.total .ag-row {
  font-weight: 700;
}
.ag-grid-table.total .ag-header {
  border: none;
}
</style>
